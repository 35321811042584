import "typeface-roboto";
import React, { Suspense, useCallback, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useQuery } from "react-query";

import Layout from "hoc/Layout";
import theme from "styles/theme";
import { AppContext } from "contexts";
import { useAuth } from "utils/hooks";
import { PAGES, sidebarItems } from "utils/links/pages";
import Notification from "components/UI/Notification";
import { getCurrentUser, getCurrentUserPermissions, getCurrentEducator } from "api/auth";
import { EducatorMapper } from "api/educator/educator.mapper";
import { getAllEducators } from "api/educator";
import { QUERY_KEY } from "api/query-keys";
import { getCategories } from "api/category";
import { getLanguages } from "api/language";

import Livechat from "components/Livechat";

const DELAY_TIME = 100;

const SignIn = loadable(() => pMinDelay(import("containers/Auth/SignIn"), DELAY_TIME));
const ForgotPassword = loadable(() => pMinDelay(import("containers/Auth/ForgotPassword"), DELAY_TIME));
const ResetPassword = loadable(() => pMinDelay(import("containers/Auth/ResetPassword"), DELAY_TIME));
const SignOut = loadable(() => pMinDelay(import("containers/Auth/SignOut"), DELAY_TIME));
const Home = loadable(() => pMinDelay(import("containers/Home"), DELAY_TIME));
const Profile = loadable(() => pMinDelay(import("containers/Profile"), DELAY_TIME));
const Dashboard = loadable(() => pMinDelay(import("containers/Dashboard"), DELAY_TIME));
const Analytics = loadable(() => pMinDelay(import("containers/Analytics"), DELAY_TIME));
const Users = loadable(() => pMinDelay(import("containers/Users"), DELAY_TIME));
const AddEditUser = loadable(() => pMinDelay(import("containers/Users/AddEditUser"), DELAY_TIME));
const Roles = loadable(() => pMinDelay(import("containers/Roles"), DELAY_TIME));
const AddEditRole = loadable(() => pMinDelay(import("containers/Roles/AddEditRole"), DELAY_TIME));
const UserSubscriptions = loadable(() => pMinDelay(import("containers/UserSubscriptions"), DELAY_TIME));
const AddEditUserSubscription = loadable(() => pMinDelay(import("containers/UserSubscriptions/AddEditUserSubscription"), DELAY_TIME));
const DefaultSubscriptions = loadable(() => pMinDelay(import("containers/DefaultSubscriptions"), DELAY_TIME));
const AddEditDefaultSubscription = loadable(() => pMinDelay(import("containers/DefaultSubscriptions/AddEditDefaultSubscription"), DELAY_TIME));
const ZoomAccounts = loadable(() => pMinDelay(import("containers/ZoomAccounts"), DELAY_TIME));
const AddEditZoomAccount = loadable(() => pMinDelay(import("containers/ZoomAccounts/AddEditZoomAccount"), DELAY_TIME));
const Educators = loadable(() => pMinDelay(import("containers/Educators"), DELAY_TIME));
const AddEditEducator = loadable(() => pMinDelay(import("containers/Educators/AddEditEducator"), DELAY_TIME));
const Categories = loadable(() => pMinDelay(import("containers/Categories"), DELAY_TIME));
const AddEditCategory = loadable(() => pMinDelay(import("containers/Categories/AddEditCategory"), DELAY_TIME));
const AddEditGroupCategory = loadable(() => pMinDelay(import("containers/Categories/AddEditGroupCategory"), DELAY_TIME));
const EventsLive = loadable(() => pMinDelay(import("containers/EventsLive"), DELAY_TIME));
const Events = loadable(() => pMinDelay(import("containers/Events"), DELAY_TIME));
const AddEditEvent = loadable(() => pMinDelay(import("containers/Events/AddEditEvent"), DELAY_TIME));
const StudentResources = loadable(() => pMinDelay(import("containers/StudentResources"), DELAY_TIME));
const StudentResourceEditor = loadable(() => pMinDelay(import("containers/StudentResources/StudentResourceEditor"), DELAY_TIME));
const EducatorResources = loadable(() => pMinDelay(import("containers/ContentManagement/ResourceCenter"), DELAY_TIME));
const AddEditEducatorResources = loadable(() => pMinDelay(import("containers/ContentManagement/ResourceCenter/AddEditEducatorResource"), DELAY_TIME));
const EducatorResourcesViewTable = loadable(() => pMinDelay(import("containers/ContentManagement/ResourceCenter/EducatorResourcesViewTable"), DELAY_TIME));
const EventFAQsTable = loadable(() => pMinDelay(import("containers/ContentManagement/EventFAQs"), DELAY_TIME));
const AddEditEventFAQ = loadable(() => pMinDelay(import("containers/ContentManagement/EventFAQs/AddEditEventFAQ"), DELAY_TIME));
const Tags = loadable(() => pMinDelay(import("containers/ContentManagement/Tag"), DELAY_TIME));
const AddEditTag = loadable(() => pMinDelay(import("containers/ContentManagement/Tag/AddEditTag"), DELAY_TIME));

const Schedules = loadable(() => pMinDelay(import("containers/Schedules"), DELAY_TIME));
const AddEditSchedule = loadable(() => pMinDelay(import("containers/Schedules/AddEditSchedule"), DELAY_TIME));
const Playlists = loadable(() => pMinDelay(import("containers/Playlists"), DELAY_TIME));
const AddEditPlaylist = loadable(() => pMinDelay(import("containers/Playlists/AddEditPlaylist"), DELAY_TIME));
const AcademyPlaylists = loadable(() => pMinDelay(import("containers/AcademyPlaylists"), DELAY_TIME));
const AddEditAcademyPlaylist = loadable(() => pMinDelay(import("containers/AcademyPlaylists/AddEditAcademyPlaylist"), DELAY_TIME));
const Videos = loadable(() => pMinDelay(import("containers/Videos"), DELAY_TIME));
const AddEditVideo = loadable(() => pMinDelay(import("containers/Videos/AddEditVideo"), DELAY_TIME));
const Notifications = loadable(() => pMinDelay(import("containers/Notifications"), DELAY_TIME));
const AddEditNotification = loadable(() => pMinDelay(import("containers/Notifications/AddEditNotification"), DELAY_TIME));
const ResourcePageTable = loadable(() => pMinDelay(import("containers/ResourcePage/ResourcePageTable"), DELAY_TIME));
const ResourcePageEditor = loadable(() => pMinDelay(import("containers/ResourcePage/ResourcePageEditor"), DELAY_TIME));
const OnboardingContentTable = loadable(() => pMinDelay(import("containers/OnboardingContent/OnboardingContentTable"), DELAY_TIME));
const AddEditOnboardingQuestion = loadable(() => pMinDelay(import("containers/OnboardingContent/AddEditOnboardingQuestion"), DELAY_TIME));
const RecommendationsTable = loadable(() => pMinDelay(import("containers/Recommendations/RecommendationsTable"), DELAY_TIME));
const AddEditRecommendation = loadable(() => pMinDelay(import("containers/Recommendations/AddEditRecommendation"), DELAY_TIME));
const RecommendationsSettings = loadable(() => pMinDelay(import("containers/RecommendationsSettings"), DELAY_TIME));
const AutoResponseEmails = loadable(() => pMinDelay(import("containers/AutoResponseEmails"), DELAY_TIME));
const AdminActionsLog = loadable(() => pMinDelay(import("containers/AdminActionsLog"), DELAY_TIME));
const Languages = loadable(() => pMinDelay(import("containers/Languages"), DELAY_TIME));
const AddEditLanguage = loadable(() => pMinDelay(import("containers/Languages/AddEditLanguage"), DELAY_TIME));
const DelayedJobs = loadable(() => pMinDelay(import("containers/DelayedJobs"), DELAY_TIME));
const Timezones = loadable(() => pMinDelay(import("containers/Timezones"), DELAY_TIME));
const AddEditTimezone = loadable(() => pMinDelay(import("containers/Timezones/AddEditTimezone"), DELAY_TIME));
const PolicyEditor = loadable(() => pMinDelay(import("containers/PolicyEditor"), DELAY_TIME));
const Paths = loadable(() => pMinDelay(import("containers/Paths"), DELAY_TIME));
const EditPath = loadable(() => pMinDelay(import("containers/Paths/PathEditor/PathStructure"), DELAY_TIME));
const StreamingServices = loadable(() => pMinDelay(import("containers/StreamingServices"), DELAY_TIME));
const MaintenanceSettings = loadable(() => pMinDelay(import("containers/MaintenanceSettings"), DELAY_TIME));
const QuestionManagement = loadable(() => pMinDelay(import("containers/QuestionManagement"), DELAY_TIME));
const QuestionManagementForm = loadable(() => pMinDelay(import("containers/QuestionManagement/Form"), DELAY_TIME));
const FeedbackAnalytics = loadable(() => pMinDelay(import("containers/FeedbackAnalytics"), DELAY_TIME));
const FeedbackAnalyticsDetails = loadable(() => pMinDelay(import("containers/FeedbackAnalyticsDetails"), DELAY_TIME));
const FeedbackEducatorDetails = loadable(() => pMinDelay(import("containers/FeedbackAnalytics/EducatorsAnalytics/EducatorDetailsInfo"), DELAY_TIME));

const TestPage = loadable(() => pMinDelay(import("test"), DELAY_TIME));

const App = () => {
  const { isLoggedIn } = useAuth();
  const [isOpenSidebar, setOpenSidebar] = useState(true);
  const [loadingInfo, setLoading] = useState(false); // setLoading(true or false) or setLoading({label: 'Please wait'})
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryLanguagesMap, setCategoryLanguagesMap] = useState({});
  const [educators, setEducators] = useState([]);
  const [categoryIdToTitleMap, setCategoryIdToTitleMap] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [currentUserPermissions, setCurrentUserPermissions] = useState([]);
  const [currentEducator, setCurrentEducator] = useState(null);

  useQuery([QUERY_KEY.GET_LANGUAGES], getLanguages, {
    enabled: !!isLoggedIn,
    onSuccess: ({ items: languages }) => {
      setLanguages(languages.map((language) => ({ ...language, displayName: language.title })));
    },
  });

  useQuery([QUERY_KEY.APP_CONTEXT_GET_CATEGORIES], getCategories, {
    enabled: !!isLoggedIn,
    onSuccess: (paginatedData) => {
      const categories = paginatedData?.items || [];
      const categoryLanguagesMap = categories.reduce((acc, category) => {
        acc[category.id] = category.distinctLanguages;
        return acc;
      }, {});

      setCategories(categories);
      setCategoryLanguagesMap(categoryLanguagesMap);
      setCategoryIdToTitleMap(categories.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.title }), {}));
    },
  });

  useQuery([QUERY_KEY.APP_CONTEXT_GET_ALL_EDUCATORS, { subcategories: true, channels: true, user: true }], getAllEducators, {
    enabled: !!isLoggedIn,
    onSuccess: (educators) => {
      const normalizedEducator = educators.map((item) => EducatorMapper(item));
      setEducators(normalizedEducator);
    },
  });

  useQuery([QUERY_KEY.CURRENT_USER], getCurrentUser, {
    enabled: !!isLoggedIn,
    onSuccess: (data) => {
      setCurrentUser(data);
    },
  });

  useQuery([QUERY_KEY.CURRENT_USER_PERMISSIONS], getCurrentUserPermissions, {
    enabled: !!isLoggedIn,
    onSuccess: (data) => {
      setPermissions(data);
    },
  });

  useQuery([QUERY_KEY.CURRENT_EDUCATOR, { channels: true }], getCurrentEducator, {
    enabled: !!isLoggedIn,
    retry: 0,
    onSuccess: (data) => {
      setCurrentEducator(data);
    },
  });

  const setPermissions = useCallback((permissions) => {
    setCurrentUserPermissions(permissions.map((item) => item.name));
  }, []);

  let sidebarSubItems = [];
  sidebarItems.forEach((sidebarItem) => {
    if (sidebarItem.link) {
      sidebarSubItems = [...sidebarSubItems, sidebarItem];
    }
    if (sidebarItem.subItems) {
      sidebarSubItems = [...sidebarSubItems, sidebarItem.subItems];
    }
  });

  return (
    <AppContext.Provider
      value={{
        isOpenSidebar,
        setOpenSidebar,
        loadingInfo,
        setLoading,
        categoryIdToTitleMap,
        educators,
        categories,
        categoryLanguagesMap,
        currentUser,
        permissions: currentUserPermissions,
        setPermissions,
        currentEducator,
        languages,
      }}
    >
      <ThemeProvider theme={theme}>
        <DndProvider backend={HTML5Backend}>
          <CssBaseline />
          <Notification />
          {isLoggedIn && <Livechat />}
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Route
                render={() =>
                  isLoggedIn ? (
                    <Layout>
                      <Switch>
                        <Route exact path={PAGES.DASHBOARD} component={Dashboard} />
                        <Route exact path={PAGES.ANALYTICS} component={Analytics} />
                        <Route exact path={PAGES.USERS} component={Users} />
                        <Route exact path={`${PAGES.USERS}/:id`} component={AddEditUser} />
                        <Route exact path={PAGES.ROLES} component={Roles} />
                        <Route exact path={`${PAGES.ROLES}/:id`} component={AddEditRole} />
                        <Route exact path={`${PAGES.USER_SUBSCRIPTIONS}`} component={UserSubscriptions} />
                        <Route exact path={`${PAGES.USER_SUBSCRIPTIONS}/:id`} component={AddEditUserSubscription} />
                        <Route exact path={`${PAGES.DEFAULT_SUBSCRIPTIONS}`} component={DefaultSubscriptions} />
                        <Route exact path={`${PAGES.DEFAULT_SUBSCRIPTIONS}/:id`} component={AddEditDefaultSubscription} />
                        <Route exact path={`${PAGES.ZOOM_ACCOUNTS}`} component={ZoomAccounts} />
                        <Route exact path={`${PAGES.ZOOM_ACCOUNTS}/:id`} component={AddEditZoomAccount} />
                        <Route exact path={PAGES.EDUCATORS} component={Educators} />
                        <Route exact path={`${PAGES.EDUCATORS}/:id`} component={AddEditEducator} />
                        <Route exact path={PAGES.CATEGORIES} component={Categories} />
                        <Route exact path={`${PAGES.CATEGORIES}/:id`} component={AddEditCategory} />
                        <Route exact path={`${PAGES.GROUP_CATEGORIES}/:id`} component={AddEditGroupCategory} />
                        <Route exact path={PAGES.EVENTS} component={Events} />
                        <Route exact path={`${PAGES.EVENTS}/:id`} component={AddEditEvent} />
                        <Route exact path={PAGES.EVENTS_LIVE} component={EventsLive} />
                        <Route exact path={PAGES.SCHEDULES} component={Schedules} />
                        <Route exact path={`${PAGES.SCHEDULES}/:id`} component={AddEditSchedule} />
                        <Route exact path={PAGES.PLAYLISTS} component={Playlists} />
                        <Route exact path={`${PAGES.PLAYLISTS}/:id`} component={AddEditPlaylist} />
                        <Route exact path={PAGES.ACADEMY_PLAYLISTS} component={AcademyPlaylists} />
                        <Route exact path={`${PAGES.ACADEMY_PLAYLISTS}/:id`} component={AddEditAcademyPlaylist} />
                        <Route exact path={PAGES.RESOURCES} component={StudentResources} />
                        <Route exact path={`${PAGES.RESOURCES}/:id`} component={StudentResourceEditor} />
                        <Route exact path={PAGES.EDUCATOR_RESOURCES} component={EducatorResources} />
                        <Route exact path={`${PAGES.EDUCATOR_RESOURCES_VIEW}`} component={EducatorResourcesViewTable} />
                        <Route exact path={`${PAGES.EDUCATOR_RESOURCES}/:id`} component={AddEditEducatorResources} />
                        <Route exact path={`${PAGES.EVENT_FAQS}`} component={EventFAQsTable} />
                        <Route exact path={`${PAGES.EVENT_FAQS}/:id`} component={AddEditEventFAQ} />
                        <Route exact path={`${PAGES.TAGS}`} component={Tags} />
                        <Route exact path={`${PAGES.TAGS}/:id`} component={AddEditTag} />
                        <Route exact path={PAGES.VIDEOS} component={Videos} />
                        <Route exact path={`${PAGES.VIDEOS}/:id`} component={AddEditVideo} />
                        <Route exact path={PAGES.NOTIFICATIONS} component={Notifications} />
                        <Route exact path={`${PAGES.NOTIFICATIONS}/:id`} component={AddEditNotification} />
                        <Route exact path={`${PAGES.RESOURCE_PAGE}`} component={ResourcePageTable} />
                        <Route exact path={`${PAGES.RESOURCE_PAGE}/:id`} component={ResourcePageEditor} />
                        <Route exact path={`${PAGES.ONBOARDING_CONTENT}`} component={OnboardingContentTable} />
                        <Route exact path={`${PAGES.ONBOARDING_CONTENT}/:id`} component={AddEditOnboardingQuestion} />
                        <Route exact path={`${PAGES.RECOMMENDATIONS}`} component={RecommendationsTable} />
                        <Route exact path={`${PAGES.RECOMMENDATIONS}/settings`} component={RecommendationsSettings} />
                        <Route exact path={`${PAGES.RECOMMENDATIONS}/:id`} component={AddEditRecommendation} />
                        <Route exact path={PAGES.AUTORESPONSE_EMAILS} component={AutoResponseEmails} />
                        <Route exact path="/test" component={TestPage} />
                        <Route exact path={PAGES.HOME} component={Home} />
                        <Route exact path={PAGES.PROFILE} component={Profile} />
                        <Route exact path={PAGES.ADMIN_ACTIONS_LOG} component={AdminActionsLog} />
                        <Route exact path={PAGES.LANGUAGES} component={Languages} />
                        <Route exact path={`${PAGES.LANGUAGES}/:id`} component={AddEditLanguage} />
                        <Route exact path={PAGES.DELAYED_JOBS} component={DelayedJobs} />
                        <Route exact path={PAGES.TIMEZONES} component={Timezones} />
                        <Route exact path={`${PAGES.TIMEZONES}/:id`} component={AddEditTimezone} />
                        <Route exact path={PAGES.POLICY_EDITOR} component={PolicyEditor} />
                        <Route exact path={PAGES.PATHS} component={Paths} />
                        <Route exact path={`${PAGES.PATHS}/:id`} component={EditPath} />
                        <Route exact path={PAGES.STREAMING_SERVICES} component={StreamingServices} />
                        <Route exact path={PAGES.MAINTENANCE_SETTINGS} component={MaintenanceSettings} />
                        <Route exact path={PAGES.QUESTION_MANAGEMENT} component={QuestionManagement} />
                        <Route exact path={PAGES.QUESTION_MANAGEMENT + "/:id"} component={QuestionManagementForm} />
                        <Route exact path={PAGES.FEEDBACK_ANALYTICS + "/:type"} component={FeedbackAnalytics} />
                        <Route exact path={PAGES.FEEDBACK_ANALYTICS + "/:type/educator-details/:id"} component={FeedbackEducatorDetails} />
                        <Route exact path={PAGES.FEEDBACK_ANALYTICS + "/:type/feedback-details/:id"} component={FeedbackAnalyticsDetails} />
                        <Redirect to={PAGES.HOME} />
                      </Switch>
                    </Layout>
                  ) : (
                    <Switch>
                      <Route exact path={PAGES.SIGN_IN} component={SignIn} />
                      <Route exact path={PAGES.FORGOT_PASSWORD} component={ForgotPassword} />
                      <Route exact path={PAGES.RESET_PASSWORD} component={ResetPassword} />
                      <Route exact path={PAGES.SIGN_OUT} component={SignOut} />
                      <Redirect to={PAGES.SIGN_IN} />
                    </Switch>
                  )
                }
              />
            </Switch>
          </Suspense>
        </DndProvider>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default App;
